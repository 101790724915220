<template>
    <v-container>
        <BaseBreadcrumb :title="title" :breadcrumbs="breadcrumbs" />
        <base-card :heading=title class="mb-6">
            <v-row class="pa-5">
                <v-col v-for="material, index in materiasComputed" :key="index" cols="12" md="4" sm="12">
                    <v-card class="pa-5" width="500">
                        <v-card-title>
                            <span class="!text-sm">{{ material.curricularUnit }}</span>
                        </v-card-title>
                        <v-card-text>
                            <span>{{ material.title}}</span>
                        </v-card-text>
                        <v-card-actions class="flex justify-end items-center">
                            <v-btn color="primary" text @click="openMaterial(material.link)">Ver material</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </base-card>
    </v-container>
</template>

<script>
import collect from 'collect.js'
import { mapMutations } from 'vuex'

export default {
  name: 'SupportMaterialView',
  data: () => ({
    organizationId: localStorage.getItem('ACTIVE_ORGANIZATION'),
    title: 'Materiais de apoio',
    school_grades: [],
    courses: [],
    breadcrumbs: [
      { text: 'Início', disabled: false, to: '/' },
      { text: 'Materiais de apoio', disabled: false, to: '/support-material' }
    ],
    materiais: [
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 1º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_1ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '1º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 2º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_2ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '2º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 3º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_3ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '3º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 4º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_4ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '4º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 5º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_5ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '5º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 6º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_6ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '6º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 7º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_7ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '7º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 8º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_8ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '8º Ano'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 9º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_9ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '9º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 1º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_1ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '1º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 2º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_2ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '2º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 3º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_3ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '3º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 4º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_4ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '4º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 5º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_5ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '5º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 6º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_6ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '6º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 7º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_7ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '7º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 8º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_8ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '8º Ano'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 9º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_9ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '9º Ano'
      },
      {
        title: 'SABE BRASIL - CIÊNCIAS HUMANAS - 9º ANO',
        curricularUnit: 'Ciências Humanas',
        link: 'https://issuu.com/editoralt/docs/ch_9ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '9º Ano'
      },
      {
        title: 'SABE BRASIL - CIÊNCIAS NATUREZA - 9º ANO',
        curricularUnit: 'Ciências da Natureza',
        link: 'https://issuu.com/editoralt/docs/cn_9ano_aluno?fr=xKAE9_zU1NQ',
        schoolGradeRef: '9º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Matemática - 1º ANO',
        curricularUnit: 'Jogos de Matemática',
        link: 'https://sabebrasil.gameseditoralt.com.br/1Ano/matematica/',
        schoolGradeRef: '1º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Matemática - 2º ANO',
        curricularUnit: 'Jogos de Matemática',
        link: 'https://sabebrasil.gameseditoralt.com.br/2Ano/matematica/',
        schoolGradeRef: '2º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Matemática - 3º ANO',
        curricularUnit: 'Jogos de Matemática',
        link: 'https://sabebrasil.gameseditoralt.com.br/3Ano/matematica/',
        schoolGradeRef: '3º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Matemática - 4º ANO',
        curricularUnit: 'Jogos de Matemática',
        link: 'https://sabebrasil.gameseditoralt.com.br/4Ano/matematica/',
        schoolGradeRef: '4º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Matemática - 5º ANO',
        curricularUnit: 'Jogos de Matemática',
        link: 'https://sabebrasil.gameseditoralt.com.br/5Ano/matematica/',
        schoolGradeRef: '5º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Matemática - 6º ANO',
        curricularUnit: 'Jogos de Matemática',
        link: 'https://sabebrasil.gameseditoralt.com.br/6Ano/matematica/',
        schoolGradeRef: '6º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Português - 1º ANO',
        curricularUnit: 'Jogos de Português',
        link: 'https://sabebrasil.gameseditoralt.com.br/1Ano/portugues/',
        schoolGradeRef: '1º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Português - 2º ANO',
        curricularUnit: 'Jogos de Português',
        link: 'https://sabebrasil.gameseditoralt.com.br/2Ano/portugues/',
        schoolGradeRef: '2º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Português - 3º ANO',
        curricularUnit: 'Jogos de Português',
        link: 'https://sabebrasil.gameseditoralt.com.br/3Ano/portugues/',
        schoolGradeRef: '3º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Português - 4º ANO',
        curricularUnit: 'Jogos de Português',
        link: 'https://sabebrasil.gameseditoralt.com.br/4Ano/portugues/',
        schoolGradeRef: '4º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Português - 5º ANO',
        curricularUnit: 'Jogos de Português',
        link: 'https://sabebrasil.gameseditoralt.com.br/5Ano/portugues/',
        schoolGradeRef: '5º Ano'
      },
      {
        title: 'SABE BRASIL - Jogos de Português - 6º ANO',
        curricularUnit: 'Jogos de Português',
        link: 'https://sabebrasil.gameseditoralt.com.br/6Ano/portugues/',
        schoolGradeRef: '6º Ano'
      }
    ]
  }),
  methods: {
    ...mapMutations(['setLoading']),
    openMaterial (link) {
      window.open(link, '_blank')
    },
    getMySchoolGrades () {
      this.setLoading(true)
      this.$axios.get(`/organizations/${this.organizationId}/lists/school-grades`)
        .then((response) => {
          this.school_grades = response.data.items
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    getMyCourses () {
      this.setLoading(true)
      this.$axios.get(`/organizations/${this.organizationId}/lists/courses`)
        .then((response) => {
          this.courses = response.data.items
        })
        .finally(() => {
          this.setLoading(false)
        })
    }
  },
  computed: {
    mySchoolGrades () {
      return collect(this.courses).map((course) => {
        const schoolGrades = collect(this.school_grades).where('id', course.school_grade_id).first()
        return {
          id: schoolGrades.id ?? null,
          name: schoolGrades.name ?? null
        }
      })
    },
    materiasComputed () {
      const schoolGradesRefs = collect(this.mySchoolGrades).pluck('name').toArray()
      return collect(this.materiais)
        .whereIn('schoolGradeRef', schoolGradesRefs)
        .push({
          title: 'Jogos de Língua Portuguesa e Matemática',
          curricularUnit: 'Jogos SABE Brasil',
          link: 'https://sabebrasil.gameseditoralt.com.br/'
        })
        .all() ??
        []
    }
  },
  mounted () {
    this.getMyCourses()
    this.getMySchoolGrades()
  }
}
</script>
